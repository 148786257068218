<template>
    <div class="logList">
        <div class="logButton">
            <div class="search">
                <!-- 搜索 -->
                <el-select filterable remote v-model="status" placeholder="请选择状态" @change="handleSearchColleague"
                    class="">
                    <el-option v-for="item in managerstatusList" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </div>
        </div>
        <!-- 列表 -->
        <el-card>
            <!-- <el-table ref="multipleTable" :data="list.slice((page - 1) * pageSize, page * pageSize)" -->
            <el-table ref="multipleTable" :data="list"
                tooltip-effect="dark" style="width: 100%">
                <el-table-column prop="id" label="id" width="100"></el-table-column>
                <el-table-column prop="content" width="700" label="申请内容"></el-table-column>
                <el-table-column prop="addtime" label="申请时间"></el-table-column>
                <el-table-column prop="username" label="申请人"></el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button type="primary" @click="updateApplyStatus(scope.row)" v-if="scope.row.status == 0">处理完成</el-button>
                        <span v-if="scope.row.status == 1">已处理</span>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination :current-page.sync="page" background :page-size="pageSize" layout="prev, pager, next"
                :total="total"
                @current-change="handleCurrentChange"></el-pagination>
        </el-card>
    </div>
</template>

<script>
// 引入试题列表js
import logList from '@/assets/js/manager/apply/index.js';
export default {
    ...logList,
};
</script>

<style lang="scss" scoped>// 引入试题列表组件css
@import '@/assets/css/manager/center/index.scss';</style>
